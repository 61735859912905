import { environment } from '@internal/environment/environment'
import 'i18n/i18n'
import { appWithTranslation } from 'next-i18next'
import type { AppProps } from 'next/app'
import Script from 'next/script'
import React from 'react'
import '../styles/globals.scss'
const { GTM_ID } = environment

const MyApp: React.FC<AppProps> = ({ Component, pageProps }) => (
  <>
    <Script
      strategy='afterInteractive'
      type='text/javascript'
      src='//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js'
    />

    <Script
      src='https://www.youtube.com/iframe_api'
      strategy='afterInteractive'
    />
    <Component {...pageProps} />
  </>
)

export default React.memo(appWithTranslation(MyApp))
