import {
  ConvertedToObjectType,
  TranslationJsonType,
} from '@website-shared-library/machine/i18n/Translation'

export const translations: ConvertedToObjectType<TranslationJsonType> =
  {} as any

/*
 * Converts the static JSON file into an object where keys are identical
 * but values are strings concatenated according to syntax.
 * This is helpful when using the JSON file keys and still having the intellisense support
 * along with type-safety
 */
export const convertLanguageJsonToObject = (json: any) => {
  for (let key in json) {
    translations[key] = key
  }
}
